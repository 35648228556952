import React from "react"
import { Link } from "gatsby"

import Layout from "../components/custom-components/Layout"

import "../css/custom-css/custom-contact-us.css"

// import bg from "../images/contact-us/header-contact-us.webp"
import bg from "../images/contact-us/header-contact.jpg"
import Seo from "../components/seo"

const ContactUs = () => {
  return (
    <Layout>
      <Seo title="Contact Us" />

      {/* Header */}
      <div className="page-content bg-white">
        <div
          className="dlab-bnr-inr header-contact overlay-black-middle bg-pt"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="hero-content">
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <div className="d-flex flex-column text-left entry-contact">
                  <h1 className="text-white">Contact Us</h1>
                </div>
                <div className="breadcrumb-row entry-contact">
                  <ul className="list-inline entry-contact">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us */}
      <div className="section-full content-inner bg-white contact-style-1">
        <div className="container">
          {/* <h2 className="m-b5">Quick Contact</h2>
          <p className="w-75">
            Innogene Kalbiotech has two main offices in Singapore and Indonesia.
            Our headquarters in Singapore is in Bukit Batok and our office in
            Indonesia is in the capital, Jakarta.
          </p> */}
          <div className="row">
            <div className="col-lg-4 d-flex m-b30">
              <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                <h3 className="m--b5">Singapore Office</h3>
                {/* <h3 className="mb-2">Singapore Office</h3> */}
                {/* <p>
                  If you have any questions simply use the following contact
                  details.
                </p> */}
                <ul className="no-margin">
                  <li className="icon-bx-wraper left m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-location-pin"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        Address
                      </h6>
                      <p>
                        21 Bukit Batok Crescent
                        <br /> #28-79 WCEGA Tower <br /> Singapore - 658065
                      </p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left  m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-email"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">Email</h6>
                      <p>info.igk@innogene.com.sg</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-mobile"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
                      <p>+65 6501-0400</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-8 d-flex m-b30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15954.92791468089!2d103.759552!3d1.33707!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da106e23a968ad%3A0x6778c44668f24a8d!2sWcega%20Tower!5e0!3m2!1sen!2ssg!4v1654757952461!5m2!1sen!2ssg"
                style={{ border: "0", width: "100%", minHeight: "100%" }}
                allowfullscreen
              ></iframe>
            </div>

            {/* <div className="col-lg-4 d-flex m-b30">
              <div className="p-a30 border contact-area border-1 align-self-stretch radius-sm">
                <h3 className="m--b5">Indonesia Office</h3>
                <ul className="no-margin">
                  <li className="icon-bx-wraper left m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-location-pin"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">
                        Address
                      </h6>
                      <p>
                        Jl. Pulogadung No.23, RW.9, Jatinegara, Kec. Cakung,
                        Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta 13930
                      </p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left  m-b30">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-email"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">Email</h6>
                      <p>biz.info@innogene.com.sg</p>
                    </div>
                  </li>
                  <li className="icon-bx-wraper left">
                    <div className="icon-bx-xs border-1">
                      {" "}
                      <Link to="/#" className="icon-cell">
                        <i className="ti-mobile"></i>
                      </Link>{" "}
                    </div>
                    <div className="icon-content">
                      <h6 className="text-uppercase m-tb0 dlab-tilte">PHONE</h6>
                      <p>+65 6501-0400</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div> 

            <div className="col-lg-8 d-flex m-b30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.644644497383!2d106.89358941475415!3d-6.178299595527124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f51f5d3280b3%3A0x2e6dafd032cdc87a!2sInnogene-Kalbiotech!5e0!3m2!1sen!2sid!4v1654758055685!5m2!1sen!2sid"
                style={{ border: "0", width: "100%", minHeight: "100%" }}
                allowfullscreen
              ></iframe>
            </div> */}

            <div className="col-lg-12 mb-4 m-b30 mb-md-0 mt-5">
              <div className="p-a30 bg-gray clearfix radius-sm">
                <h3 className="m-b10">Send Us Message</h3>
                <div className="dzFormMsg"></div>
                <form
                  className="dzForm"
                  acceptCharset="utf-8"
                  // action="https://getform.io/f/afc51cde-6aab-4b5a-a572-73a994e1aeba"
                  // action="https://formspree.io/f/xnqwpzre"
                  action="https://formspree.io/f/mqkjyeby"
                  method="POST"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            required
                            placeholder="E-mail"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="input-group">
                          <textarea
                            name="message"
                            rows="4"
                            className="form-control"
                            required
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="site-button btnhover11">
                        <span>Submit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs

{
  /* <div className="m-t20">
                  <ul className="dlab-social-icon border dlab-social-icon-lg">
                    <li>
                      <div className="icon-bx-xs border-1">
                        <a
                          target="_blank"
                          href="https://www.youtube.com/channel/UCvD1k5epzEmVR40J_KYCinQ"
                          className="icon-cell bg-primary"
                        >
                          <i className="fa fa-youtube-play"></i>
                        </a>
                      </div>
                    </li>
                    <li>
                      <div className="icon-bx-xs border-1">
                        <a
                          target="_blank"
                          href="https://id.linkedin.com/company/kalbio-global-medika"
                          className="icon-cell bg-primary"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div> */
}
